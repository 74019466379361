.custom-popover-wrapper{
    border: 1px solid;
    box-shadow: 3px 6px #888888;

    .custom-popover-inner {
        .popover-body {
            padding-top: 0;
            padding-left: 0;
        }
    }
}

//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.simpleTable {
  margin: 0;
  td {
    border: none;
    border-right: 1px solid #eff2f7;
    line-height: 4;
  }
  td:last-child {
    line-height: 4;
    border-right: none;
  }
}

.customTd {
  padding: 0 12px !important;
}

.delivery {
  display: flex;
  justify-content: space-between;
  .mb-0 {
    margin-right: 10px;
  }
}

.customColumn {
  max-width: 35px;
}

.simple-mode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 4;
}

.item-simple {
  border-right: 1px solid #eff2f7;
}

.comment-simple {
  border-right: 1px solid #eff2f7;
  min-width: 50%;
}

.item-simple:last-child,
.comment-simple:last-child {
  border-right: none;
}

.category-name {
  width: 50%;
  .column-content {
    width: 80%;
    padding-left: 20%;
  }
}

.category-product-amount {
  width: 40%;
}

.category-action {
  width: 10%;
}

.reset-expansion-style {
  padding: 0.75rem 0 !important;

  .react-bootstrap-table-pagination {
    display: none;
  }
}

.column-content {
  width: 99%;
  overflow: hidden;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
}


.group-name {
  width: 25%;

  .column-content {
    width: 80%;
    padding-left: 20%;
  }
}

.group-description {
  width: 35%;
}

.group-categories {
  width: 15%;
}

.group-created-date {
  width: 15%;
}

.group-action {
  width: 10%;
}

.table-print {
  width: 168px;
}

.comment-print {
  background-color: #eff2f7;
  font-weight: 600
}

.break-line {
  white-space: pre-line;
  margin-bottom: 12px;
}